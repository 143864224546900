const menu = [
  {
    icon: 'fa fa-calendar',
    title: 'calendar.pageTitle',
    to: '/',
    color: ''
  },
  {
    icon: 'fas fa-desktop',
    title: 'dashboard.pageTitle',
    to: '/dashboard'
  },
  {
    icon: 'mdi-shuffle-variant',
    title: 'integrations.pageTitle',
    to: '/integrations'
  },
  {
    icon: 'fas fa-cog',
    title: 'preferences.pageTitle',
    to: '/preferences'
  },
  {
    icon: 'fas fa-user',
    title: 'account.menuTitlle',
    to: '/account'
  },
  {
    icon: 'mdi mdi-book-open-variant',
    title: 'contentpage.menuTitle',
    to: '/content'
  }
]

const companyUserMenuItems = [
  {
    icon: 'fas fa-users',
    title: 'coworkers.pageTitle',
    to: '/coworkers'
  },
  {
    icon: 'fas fa-lightbulb',
    title: 'iotDevice.menuTitle',
    to: '/iotDevice'
  }
]

const gcalendarColors = [
  // rose clair #e67c73
  {
    background: '#6a5acd',
    foreground: '#fff'
  },
  {
    background: '#7ae7bf',
    foreground: '#1d1d1d'
  },
  {
    background: '#dbadff',
    foreground: '#1d1d1d'
  },
  {
    // rose clair ????? ou orange ???
    background: '#ff887c',
    foreground: '#1d1d1d'
  },
  {
    background: '#fbd75b',
    foreground: '#1d1d1d'
  },
  {
    background: '#ffb878',
    // background: '#190970',
    foreground: '#1d1d1d'
  },
  {
    background: '#46d6db',
    foreground: '#1d1d1d'
  },
  {
    background: '#e1e1e1',
    foreground: '#1d1d1d'
  },
  {
    background: '#5484ed',
    foreground: '#fff'
  },
  {
    background: '#51b749',
    foreground: '#1d1d1d'
  },
  {
    background: '#dc2127',
    foreground: '#fff'
  }
]

const colors = {
  // primary: '#34ae35',
  // secondary: '#92d202',
  // accent: '#e2e8f0'
  primary: '#f4a928',
  secondary: '#6a5acd',
  accent: '#e2e8f0'
}
module.exports = {
  menu,
  gcalendarColors,
  companyUserMenuItems,
  colors
}
