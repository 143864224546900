export default function ({ store, redirect }) {
//   // If the user is not authenticated
//   // console.log('user/data = ', store.getters['user/data'])
//   // console.log('user/isAuthenticated ', store.getters['user/isAuthenticated'])
//   // console.log('getters ', store.getters)
//   if (!store.getters['user/isAuthenticated']) {
//     return redirect('/auth/login')
//   } else if (!store.getters['user/data']) {
//     store.dispatch('user/FETCH_USER')
//       .then(({ status }) => {
//         if (!status) { console.log('Failed to fetch user') }
//       })
//   }
}
