import actions from './actions'
import mutations from './mutations'
import Storage from '~/misc/storage'

export const state = () => ({
  info: Storage.getObject('focus') || null
})

export const getters = {
  focusInfo: state => state.info
}

export { mutations }
export { actions }
