import backend from '~/misc/api-routes'

const actions = {
  async FETCH_APPS_URLS ({ commit }) {
    return await this.$axios.get(backend.OAUTH_URLS).then((resp) => {
      const urls = resp.data
      commit('SET_OAUTH_URLS', urls)
      return resp
    })
      .catch((err) => {
        console.log(err.reponse)
        throw err
      })
  },
  async UPDATE_FOCUS_PREFERENCES (_context, preferences) {
    return await this.$axios.put(backend.UPDATE_USER_SETTINGS, {
      action: 'update_focus_preferences',
      preferences
    }).then((resp) => {
      return {
        status: true,
        data: resp.data
      }
    }).catch((err) => {
      return {
        status: false,
        data: err
      }
    })
  }
}

export default actions
