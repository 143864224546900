import Storage from '~/misc/storage'

const WEEKLY_USAGE = 'weekly_usage'

const mutations = {
  SET_WEEKLY_USAGE: (state, stats) => {
    state.weekly_usage = stats
    // Store the object on localstorage
    Storage.setObject(WEEKLY_USAGE, stats)
  },
  SET_WEEK_SPAN_STATS: (state, stats) => {
    state.week_span_stats = stats
  },
  SET_MONTH_SPAN_STATS: (state, stats) => {
    state.month_span_stats = stats
  }
}

export default mutations
