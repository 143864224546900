import actions from './actions'
import mutations from './mutations'
import Storage from '~/misc/storage'

const WEEKLY_USAGE = 'weekly_usage'

const defaultData = function (weekly = true) {
  const list = new Array(weekly ? 7 : 30).fill(0)
  const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]
  const monthDays = Array.from({ length: 30 }, (_, i) => i + 1)
  return {
    dates: list,
    days: weekly ? weekDays : monthDays,
    activations: list,
    durations: list
  }
}

export const state = () => ({
  weekly_usage: Storage.getObject(WEEKLY_USAGE),
  week_span_stats: {
    defaultData: true,
    current_stats: defaultData(),
    previous_stats: defaultData()
  },
  month_span_stats: {
    defaultData: true,
    current_stats: defaultData(false),
    previous_stats: defaultData(false)
  }
})

export const getters = {
  weekly_usage: state => state.weekly_usage,
  week_span: state => state.week_span_stats,
  month_span: state => state.month_span_stats
}

export { mutations }
export { actions }
