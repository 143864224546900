
import { mapGetters } from 'vuex'
import { colors } from '~/misc/application-data'
import logo from '@/static/menu/logo.svg'
import FooterAuth from '~/components/FooterAuth.vue'
import Message from '~/components/Dialogs/Messages'

export default {
  components: { FooterAuth, Message },
  data () {
    return {
      logo,
      navbarOpen: false,
      clipped: true,
      drawer: true,
      fixed: false,
      items2: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' }
      ],
      items: [
        {
          icon: 'fas fa-desktop',
          title: 'Dashboard',
          to: '/',
          color: ''
        },
        {
          icon: 'fa fa-calendar',
          title: 'Calendar',
          to: '/calendar'
        },
        {
          icon: 'fas fa-lightbulb',
          title: 'Iot Device',
          to: '/iotDevice'
        },
        {
          icon: 'fas fa-users',
          title: 'Coworkers',
          to: '/coworkers'
        },
        {
          icon: 'mdi-shuffle-variant',
          title: 'Integrations',
          to: '/integrations'
        },
        {
          icon: 'fas fa-cog',
          title: 'Settings',
          to: '/settings'
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js'
    }
  },
  computed: {
    ...mapGetters(['getMessages']),
    secondary () {
      return colors.secondary
    }
  },
  mounted () {
    this.$store.dispatch('SET_LANG', this.$store.getters.getLang)
  },
  methods: {
    setNavbarOpen () {
      this.navbarOpen = !this.navbarOpen
    }
  }
}
