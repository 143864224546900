
/* eslint-disable no-console */
import { mapGetters } from 'vuex'
import Focus from '~/components/Focus.vue'

import { initSocket, initChannel } from '~/misc/phx-socket'

import { menu, companyUserMenuItems } from '~/misc/application-data'
import Message from '~/components/Dialogs/Messages'

export default {
  name: 'Default',
  components: { Focus, Message },
  middleware: 'authenticated',
  data () {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      userDropDown: [
        { id: 'account', title: this.$t('myaccount') },
        { id: 'disconnect', title: this.$t('disconnect') }
      ],
      menu: [...menu],
      right: true,
      rightDrawer: false,
      title: 'QualFlo'
    }
  },
  computed: {
    ...mapGetters('user', ['data', 'linkedApps', 'appsList']),
    ...mapGetters('focus', ['focusInfo']),
    ...mapGetters('settings', ['availableApps']),
    ...mapGetters(['getMessages', 'getMiniVariant']),
    fullName () {
      return `${this.data.firstname} ${this.data.lastname}`
    },
    profilePicture () {
      const picture = `https://identicapi.herokuapp.com/img/${this.data.firstname} ${this.data.lastname}/500/500`
      const gcal = this.linkedApps.find(app => app.type === 'google_calendar')
      const slack = this.linkedApps.find(app => app.type === 'slack')
      if (this.data.avatar) {
        return this.data.avatar
      } else if (slack && slack.image) {
        return slack.image
      } else if (gcal && gcal.image) {
        return gcal.image
      } else {
        return picture
      }
    }
  },
  watch: {
    appsList () {
      if (this.availableApps?.length > 0 && this.appsList?.length === 0) {
        this.menu = this.menu.map((route) => {
          if (route.to === '/integrations') {
            return { ...route, attentionRequired: true }
          }
          return route
        })
      } else {
        this.menu = this.menu.map((route) => {
          if (route.to === '/integrations') {
            return { ...route, attentionRequired: false }
          }
          return route
        })
      }
    }
  },
  async mounted () {
    this.$store.dispatch('SET_LANG', this.$store.getters.getLang)
    initSocket(this.$phxSocket, this.$auth.strategy.token.get())
    initChannel(this.$phxSocket, this.$store.dispatch, this.$auth.user.email)
    this.$store.dispatch('PROCESS_PENDING_MESSAGES')
    await this.$store.dispatch('settings/FETCH_APPS_URLS')
    await this.$store.dispatch('user/FETCH_APPS')
    if (this.menu.length <= menu.length) {
      let cUser
      switch (this.data.type) {
        case 'normal1':
          this.menu.splice(2, 0, ...companyUserMenuItems)
          break
        default:
          this.menu = [...menu]
          cUser = companyUserMenuItems.map((route) => {
            return { ...route, to: '/upgrade-to-access', color: 'black' }
          })
          this.menu.splice(2, 0, ...cUser)
          break
      }
    }
    if (this.availableApps && this.availableApps.length > 0 &&
      (!this.appsList || this.appsList.length === 0)) {
      this.menu = this.menu.map((route) => {
        if (route.to === '/integrations') {
          return { ...route, attentionRequired: true }
        }
        return route
      })
    }
  },
  destroyed () {
    if (this.$phxSocket.channel) {
      this.$phxSocket.channel.leave()
      this.$phxSocket.channel = null
    }
    if (this.$phxSocket.socket) {
      this.$phxSocket.socket.disconnect()
      this.$phxSocket.socket = null
    }
  },
  methods: {
    itemClicked (id) {
      switch (id) {
        case 'account':
          this.$router.push(this.localePath('account'))
          break
        case 'disconnect':
          this.logout()
          break
        default:
          break
      }
    },
    async logout () {
      try {
        await this.$auth.logout()
        // this.$router.push(this.localePath('/auth/login'))
        this.$store.dispatch('CLEAR_ALL_MESSAGES')
      } catch (err) {
        console.log('logout error', err)
      }
    },
    toogleMiniVariant () {
      this.$store.commit('SET_MINIVARIANT', !this.getMiniVariant)
    }
  }
}
