// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Pasajero.ttf.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Pasajero.ttf.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Pasajero.ttf.eot");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/CrystalItalic-R6A.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "#Pasajero" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sr-only:not(:focus):not(:active){clip:rect(0 0 0 0);-webkit-clip-path:inset(100%);clip-path:inset(100%);height:1px;overflow:hidden;position:absolute;white-space:nowrap;width:1px}@font-face{font-family:\"Pasajero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"svg\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:400;font-style:normal}@font-face{font-family:\"Crystal\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"svg\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:400;font-style:italic}.pasajero{font-family:\"Pasajero\"!important}.crystal{font-family:\"Crystal\"!important}.auth-layout>.v-main__wrap{display:flex!important}body,html{overflow-y:auto!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
