import actions from './actions'
import mutations from './mutations'
import Storage from '~/misc/storage'

export const state = () => ({
  // user: Storage.getObject('user') || null,
  // token: Storage.get('user-token') || '',
  // status: '',
  linked_apps: Storage.getObject('linked_apps') || []
})

export const getters = {
  // isAuthenticated: state => !!state.token,
  // isAuthenticated: () => true,
  // authStatus: state => state.status,
  // authStatus: (state, getters, rootState) => rootState.auth.loggedIn,
  // authStatus: (state, getters, rootState) => this.$auth.loggedIn,
  // getToken: state => state.token,
  data: (_state, _getters, rootState) => rootState.auth.user,
  linkedApps: state => state.linked_apps,
  appsList: (state) => {
    if (state.linked_apps) {
      return state.linked_apps.map(app => app.type)
    } else {
      return []
    }
  }
}

export { mutations }
export { actions }
