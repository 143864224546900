const actions = {
  SHOW_SUCCESS ({ commit }, { message, timeout, action }) {
    return commit('ADD_MESSAGE', {
      color: 'emerald-500',
      timeout: timeout || (action ? -1 : process.env.MESSAGE_DURATION),
      icon: 'check-circle',
      description: message,
      action: action ?? null
    })
  },
  SHOW_WARNING ({ commit }, { message, timeout, action }) {
    return commit('ADD_MESSAGE', {
      color: 'black',
      timeout: timeout || (action ? -1 : process.env.MESSAGE_DURATION),
      icon: 'alert',
      description: message,
      action: action ?? null
    })
  },
  SHOW_ERROR ({ commit }, { message, timeout, action }) {
    return commit('ADD_MESSAGE', {
      color: 'red-500',
      timeout: timeout || (action ? -1 : process.env.MESSAGE_DURATION),
      icon: 'alert-circle',
      description: message,
      action: action ?? null
    })
  },
  /**
   * Message that display only when the web page is reloaded via f5 or refresh btn or $router.go(0)
   * @param {*} param0 vue store params
   * @param {{string, any}} param1 the message type (SHOW_SUCCESS, SHOW_ERROR, ...) and the msg data
   */
  ADD_PENDING_MESSAGE ({ commit }, { type, data }) {
    commit('ADD_PENDING_MESSAGE', { type, data })
  },
  /**
   * Print all pending messages to the screen
   * @param {*} param0 vue store params
   */
  PROCESS_PENDING_MESSAGES ({ state, commit, dispatch }) {
    let [msg] = state.pendingMessages.slice(-1)
    while (msg) {
      commit('POP_PENDING_MESSAGE')
      dispatch(msg.type, msg.data);
      [msg] = state.pendingMessages.slice(-1)
    }
  },
  /**
   * Print all pending messages to the screen
   * @param {*} param0 vue store params
   */
  CLEAR_ALL_MESSAGES ({ commit }) {
    commit('CLEAR_ALL_MESSAGES')
  },
  /**
   * Switch langugage
   * @param {*} param0 vue State
   * @param {string} code language code
   */
  SET_LANG ({ state, commit }, code) {
    if (this.$i18n.localeCodes.includes(code)) {
      commit('SET_LANG', code)
      this.$i18n.setLocale(code)
    }
  }
}

export default actions
