const mutations = {
  SET_EVENTS: (state, events) => {
    state.events = events
  },
  ADD_EVENT: (state, event) => {
    state.events.push(event)
  },
  UPDATE_EVENT: (state, event) => {
    const index = state.events.findIndex(e => e.id === event.id)
    if (index > -1) {
      state.events[index] = event
    } else {
      state.events.push(event)
    }
  },
  DELETE_EVENT: (state, event) => {
    const index = state.events.findIndex(e => e.id === event.id)
    if (index > -1) {
      state.events.splice(index, 1)
    }
  }
}

export default mutations
