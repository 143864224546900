import actions from './actions'
import mutations from './mutations'
import Storage from '~/misc/storage'

export const state = () => ({
  oauth_urls: Storage.getObject('oauth_urls') || null
})

export const getters = {
  oauthUrls: state => state.oauth_urls,
  availableApps: state => state.oauth_urls ? Object.keys(state.oauth_urls) : []
}

export { mutations }
export { actions }
