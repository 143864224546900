
export default {
  props: {
    absolute: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      date: new Date().getFullYear()
    }
  }
}
