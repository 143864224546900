const actions = {
  CHANNEL_EVENT (_context, _data) {
    // console.log('dispatched data [store action CHANNEL_EVENT] => ', data)
  },
  UPDATE ({ commit, dispatch }, data) {
    commit('UPDATE_FOCUS', data)
    dispatch('calendar/FETCH_EVENTS', null, { root: true })
  },
  SET ({ commit, dispatch }, data) {
    commit('SET_FOCUS', data)
    dispatch('calendar/FETCH_EVENTS', null, { root: true })
  },
  START_FOCUS (_context) {
    this.$phxSocket.channel.push('focus:start', {})
  },
  STOP_FOCUS (_context) {
    this.$phxSocket.channel.push('focus:stop', {})
  }
}

export default actions
