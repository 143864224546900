
import { DateTime, Duration } from 'luxon'

export default {
  name: 'Focus',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    startTime: {
      type: String,
      required: true
    },
    finishTime: {
      type: String,
      required: true
    },
    focusTime: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      focused: this.value,
      now: DateTime.local(),
      start: DateTime.fromISO(this.startTime),
      finish_at: DateTime.fromISO(this.finishTime),
      tick: null
    }
  },
  computed: {
    end () {
      // return this.start ? this.start.plus({ minutes: this.focusTime }) : null
      if (this.finish_at && this.finish_at?.invalid === null) {
        return this.finish_at
      }
      return null
    },
    remainingTime () {
      if (!this.focused || this.finished) {
        return this.$t('focus.available')
      }
      const remaining = Duration.fromObject(this.end.diff(this.now).toObject())
      const display = remaining.toFormat("hh'h' mm'm' ss's'")
      // const minutes = parseInt(display.split(':')[1])
      // const minutes = 12
      return `${display}`
    },
    finished () {
      if (!this.end || !this.start) {
        return true
      }
      return this.now >= this.end
    }
  },
  watch: {
    value (newValue, oldValue) {
      this.focused = this.value
      if (oldValue && !newValue) { // stopped focus mode true => false (=> = becomes)
        this.stopFocus()
      } else if (!oldValue && newValue) { // started focus mode false => true
        this.startFocus()
      }
    },
    startTime () {
      if (this.startTime) {
        this.start = DateTime.fromISO(this.startTime)
      } else {
        this.start = null
      }
    },
    finishTime () {
      if (this.finishTime) {
        this.finish_at = DateTime.fromISO(this.finishTime)
      } else {
        this.finish_at = null
      }
    }
  },
  mounted () {
    // const dt = DateTime.fromISO('2020-12-17T11:02:00.907540Z')
    // console.log(dt.toISO())
    this.startCountDown()
    // this.start = DateTime.local().minus({ minutes: 5 })
    if (this.startTime) {
      this.start = DateTime.fromISO(this.startTime)
    } else {
      this.start = null
    }
  },
  destroyed () {
    clearInterval(this.tick)
  },
  methods: {
    startCountDown () {
      clearInterval(this.tick)
      this.tick = setInterval(() => {
        this.now = DateTime.local()
        if (this.finished) {
          clearInterval(this.tick)
        }
      },
      1000)
      return this.tick
    },
    toogleFocus () {
      if (this.focused) {
        this.makeStopFocusRequest()
      } else {
        this.makeStartFocusRequest()
      }
      // this.$emit('input', this.focused)
    },
    makeStartFocusRequest () {
      this.$store.dispatch('focus/START_FOCUS')
    },
    makeStopFocusRequest () {
      this.$store.dispatch('focus/STOP_FOCUS')
    },
    startFocus () {
      this.startCountDown()
    },
    stopFocus () {
      clearInterval(this.tick)
    }
  }
}
