import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=aaa3d5d0&scoped=true&"
import script from "./auth.vue?vue&type=script&lang=js&"
export * from "./auth.vue?vue&type=script&lang=js&"
import style0 from "./auth.vue?vue&type=style&index=0&id=aaa3d5d0&prod&lang=scss&scoped=true&"
import style1 from "./auth.vue?vue&type=style&index=1&id=aaa3d5d0&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa3d5d0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterAuth: require('/builds/qualflo/frontend/components/FooterAuth.vue').default})
