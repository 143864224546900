// import { defineNuxtPlugin } from '@nuxt/bridge'
// export default defineNuxtPlugin((nuxtApp) => {
//   nuxtApp.provide('phxSocket', () => ({
//     socket: null,
//     channel: null
//   }))
// })
export default ({ app }, inject) => {
  inject('phxSocket', {
    socket: null,
    channel: null
  })
}
