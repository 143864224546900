
import logo from '@/static/logo_horizontal_cp-2.png'
import LanguageSwitcher from '~/components/LanguageSwitcher.vue'
export default {
  components: { LanguageSwitcher },
  layout: 'auth',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      logo,
      pageNotFound: '404 Not Found',
      otherError: this.$t('anErrorOccured')
    }
  },
  computed: {
    title () {
      return this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    }
  },
  methods: {
    goToHome () {
      window.location.href = '/'
    }
  },
  head () {
    const title = this.title
    return {
      title
    }
  }
}
