export const LINKED_APPS = '/user/linkedapps'
export const DROP_LINKED_APPS = type => `/user/linkedapps/${type}`
export const GOOGLE_APP_PATH = '/user/linkedapps/google_calendar'
export const SLACK_APP_PATH = '/user/linkedapps/slack'

export const FETCH_EVENTS = '/user/calendar/events'
export const LIST_CALENDARS = '/user/calendar/list'
export const WATCH_CALENDARS = '/user/calendar/watch'

export const USER = '/user/settings'
export const CHANGE_PASSWORD = '/user/change-password'
export const UPDATE_USER_SETTINGS = '/user/settings'
export const CONFIRM_EMAIL_UPDATE = token => `/user/settings/confirm_email/${token}`

export const OAUTH_URLS = '/app_auth/oauth_urls'
export const USAGE_STATS = '/stats'
export const WEEK_STATS = '/stats/week_span'
export const MONTH_STATS = '/stats/month_span'

export const GET_RESET_PASSWORD_LINK = '/auth/reset_password'
export const RESET_PASSWORD = token => `/auth/reset_password/${token}`
export const CONFIRM_USER = token => `/auth/confirm/${token}`
export const LOGIN = '/auth/login'
export const LOGOUT = '/auth/logout'
export const REGISTER = '/auth/register'

export default {
  REGISTER,
  CONFIRM_USER,
  LOGIN,
  USER,
  LOGOUT,

  CHANGE_PASSWORD,
  GET_RESET_PASSWORD_LINK,
  RESET_PASSWORD,
  UPDATE_USER_SETTINGS,
  CONFIRM_EMAIL_UPDATE,

  LINKED_APPS,
  DROP_LINKED_APPS,
  GOOGLE_APP_PATH,
  SLACK_APP_PATH,
  OAUTH_URLS,

  USAGE_STATS,
  WEEK_STATS,
  MONTH_STATS,

  FETCH_EVENTS,
  LIST_CALENDARS,
  WATCH_CALENDARS
}
