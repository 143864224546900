const storage = localStorage
// const storage = sessionStorage;
export function set (key, value) {
  return storage.setItem(key, value)
}

export function get (key) {
  return storage.getItem(key)
}

export function remove (key) {
  return storage.removeItem(key)
}

export function getObject (key) {
  const obj = get(key)
  return (obj !== 'undefined') ? JSON.parse(obj) : null
}

export function setObject (key, value) {
  return storage.setItem(key, JSON.stringify(value))
}

export default { set, get, remove, getObject, setObject }
