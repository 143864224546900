import backend from '~/misc/api-routes'
import { setBearerToken } from '~/misc/tools'

const actions = {
  async FETCH_WEEKLY_USAGE ({ commit, rootState }) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.get(backend.USAGE_STATS)
      .then((resp) => {
        const weeklyUsage = resp.data
        commit('SET_WEEKLY_USAGE', weeklyUsage)
        return {
          status: true,
          data: resp
        }
      }).catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async FETCH_WEEK_SPAN_STATS ({ commit, rootState }) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.get(backend.WEEK_STATS)
      .then((resp) => {
        const weekSpanUsage = resp.data
        commit('SET_WEEK_SPAN_STATS', weekSpanUsage)
        return {
          status: true,
          data: resp
        }
      }).catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async FETCH_MONTH_SPAN_STATS ({ commit, rootState }) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.get(backend.MONTH_STATS)
      .then((resp) => {
        const monthSpanUsage = resp.data
        commit('SET_MONTH_SPAN_STATS', monthSpanUsage)
        return {
          status: true,
          data: resp
        }
      }).catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  }
}

export default actions
