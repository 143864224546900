
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      timeoutId: null,
      opacityTimeout: null,
      opacity: ''
    }
  },
  mounted () {
    if (this.data.timeout && this.data.timeout > 0) {
      if (this.data.timeout >= 500) {
        this.opacityTimeout = setTimeout(() => {
          this.opacity = 'opacity-0'
        }, this.data.timeout - 500)
      }
      this.timeoutId = setTimeout(() => {
        this.$store.commit('REMOVE_MESSAGE', this.index)
      }, this.data.timeout)
    }
  },
  methods: {
    hideMessage () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      if (this.opacityTimeout) {
        clearTimeout(this.opacityTimeout)
      }
      this.$store.commit('REMOVE_MESSAGE', this.index)
    },
    actionClicked () {
      if (this.data.action?.link) {
        this.$router.push(this.localePath(this.data.action.path), () => {
          this.opacity = 'opacity-0'
          setTimeout(() => {
            this.hideMessage()
          }, 500)
        })
      } else {
        this.opacity = 'opacity-0'
        setTimeout(() => {
          this.hideMessage()
        }, 500)
      }
    }
  }

}
