import { Socket } from 'phoenix'

export function initSocket ($phxSocket, token) {
  // console.log('this.$phxSocket', $phxSocket)
  // token = token.replace('Bearer ', '')
  const socketToken = localStorage.getItem('socket_token')
  if (!$phxSocket.socket) {
    // console.log("affectation de l'objet $phxSocket.socket et ouverture de la connexion")
    if (process.env.baseUrl.includes('qualflo')) {
      $phxSocket.socket = new Socket(`wss://${process.env.baseUrl}/socket`, { params: { token: socketToken } })
    } else {
      $phxSocket.socket = new Socket(`ws://${process.env.baseUrl}/socket`, { params: { token: socketToken } })
    }
    $phxSocket.socket.onOpen(() => console.log('Socket opened'))
  } else {
    console.log("L'objet $phxSocket.socket est déjà affecté")
  }
  //  socket = new Socket('ws://localhost:4000/socket', {})
  if (!$phxSocket.socket.isConnected()) {
    console.log('Tentative de connection de la websocket au backend')
    $phxSocket.socket.connect()
    console.log('Après Tentative de connection de la websocket au backend')
  } else {
    console.log('La connexion de la websocket au backend est déjà éffectuée')
  }
}

export function initChannel ($phxSocket, dispatch, channelID) {
  if (!$phxSocket.channel) {
    $phxSocket.channel = $phxSocket.socket.channel(`focus:${channelID}`)
    $phxSocket.channel.on('focus:updated',
      (response) => {
        if (response.status) {
          // console.info('focus:updated', response)
          dispatch('focus/UPDATE', response.status)
        }
      })
    // joined event)
    $phxSocket.channel.on('joined',
      (response) => {
        // console.info('focus/joined', response)
        dispatch('focus/CHANNEL_EVENT', response)
      })
    $phxSocket.channel.on('focus:info',
      (response) => {
        // console.log('Focus:info', response)
        dispatch('focus/SET', response.focus)
      })
  } else {
    // console.log("L'objet $phxSocket.channel est déjà affecté")
  }
  try {
    $phxSocket.channel.join()
    // .receive('ok', ({ messages }) => console.log('catching up!', messages))
    // .receive('error', ({ reason }) => console.log('failed join', reason))
    // .receive('timeout', () => console.log('Networking issue. Still waiting...'))
  } catch (e) { console.log('join exception', e) }
}

export default {
  initSocket,
  initChannel
}
