import Vue from 'vue'
import Vuex from 'vuex'

// import userStore from './user/'
// import calendarStore from './calendar/'
import mutations from './mutations'
import actions from './actions'
import Storage from '~/misc/storage'

Vue.use(Vuex)

export const state = () => ({
  lang: Storage.get('lang') || 'fr',
  messages: [
  ],
  // pendingMessages: Messages that display only when the web page is reloaded via f5 or refresh btn
  pendingMessages: Storage.getObject('pendingMessages') || [],
  miniVariant: Storage.getObject('miniVariant') || false
})

export const getters = {
  getMessages: state => state.messages,
  getMiniVariant: state => state.miniVariant,
  getPendingMessages: state => state.pendingMessages,
  getLang: state => state.lang
}

export { mutations }

export { actions }
