import actions from './actions'
import mutations from './mutations'
import Storage from '~/misc/storage'

export const state = () => ({
  events: Storage.getObject('events') || null
})

export const getters = {
  listEvents: state => state.events
}

export { mutations }
export { actions }
