import Storage from '~/misc/storage'

const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = 'loading'
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = 'success'
    state.token = token
  },
  AUTH_ERROR: (state) => {
    state.status = 'error'
  },
  AUTH_LOGOUT: (state) => {
    state.status = ''
    state.token = ''
    state.user = null
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_APPS: (state, apps) => {
    state.linked_apps = apps
    Storage.remove('linked_apps')
    Storage.setObject('linked_apps', apps)
  }
}

export default mutations
