import Storage from '~/misc/storage'

const mutations = {
  SET_FOCUS: (state, focus) => {
    state.info = focus
    Storage.remove('focus')
    Storage.setObject('focus', focus) // Store the object on localstorage
  },
  UPDATE_FOCUS: (state, focus) => {
    state.info = { ...state.info, ...focus }
    Storage.remove('focus')
    Storage.setObject('focus', state.info) // Store the object on localstorage
  }
}

export default mutations
