import Storage from '~/misc/storage'
const mutations = {
  /**
   * Show a snackbar message to the user
   * @param {Store.state} state
   * @param {{color: string, icon: string, description: string, timeout: number}} param1
   * the message configuration
   * @returns the index of the new message
   */
  ADD_MESSAGE: (state, { color, icon, description, timeout, action }) => {
    if (state.messages.findIndex(msg => msg.description === description) !== -1) {
      return -1
    }
    const position = state.messages.push({
      id: state.messages.length,
      description,
      icon,
      color,
      timeout,
      action
    })
    return position - 1
  },
  /**
   * Hide a snackbarpopup
   * @param {Store.state} state Vuex Global State
   * @param {number} index the position of the message in the messages array
   */
  REMOVE_MESSAGE: (state, index) => {
    state.messages = state.messages.filter(msg => msg.id !== index)
  },
  /**
   * Set sidebar minivariant value
   * @param {Store.state} state Vuex Global State
   * @param {Boolean} value the value to set
   */
  SET_MINIVARIANT: (state, value) => {
    state.miniVariant = value
    Storage.setObject('miniVariant', value)
  },
  /**
   * Add a new pending message
   * @param {Store.state} state Vuex Global State
   * @param {{String, any}} {type, data} the value to set
   */
  ADD_PENDING_MESSAGE: (state, { type, data }) => {
    state.pendingMessages.push({ type, data })
    Storage.setObject('pendingMessages', state.pendingMessages)
  },
  /**
   * Remove the last pending message
   * @param {Store.state} state Vuex Global State
   * @returns void
   */
  POP_PENDING_MESSAGE: (state) => {
    state.pendingMessages.pop()
    Storage.setObject('pendingMessages', state.pendingMessages)
  },
  /**
   * Clear (make empty) the state.messages array
   * @param {Store.state} state Vuex Global State
   * @returns void
   */
  CLEAR_ALL_MESSAGES: (state) => {
    state.messages.splice(0, state.messages.length)
  },
  SET_LANG: (state, code) => {
    state.lang = code
    Storage.set('lang', code)
  }
}

export default mutations
