
export default {
  name: 'LanguageSwitcher',
  props: {
    styleProp: {
      type: String,
      default: ''
    },
    classProp: {
      type: [String, Array, Object],
      default: ''
    },
    withName: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    solo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    itemText (item) {
      if (this.withName) {
        return item.name
      }
      return item.flag
    },
    changeLocalPath (param) {
      this.$store.dispatch('SET_LANG', param)
    }
  }
}
