import Storage from '~/misc/storage'
import backend from '~/misc/api-routes'

const actions = {
  async AUTH_REQUEST ({ commit }, user) {
    return await this.$axios.$post('auth/login', {
      email: user.email, password: user.password
    }).then((resp) => {
      const token = resp.token
      this.$axios.setToken(token, 'Bearer')
      Storage.set('user-token', token) // Store the token on localstorage
      commit('AUTH_SUCCESS', token)
      return { status: true, data: resp }
    }).catch((err) => {
      commit('AUTH_ERROR', err)
      Storage.remove('user-token') // if the request fails, remove any possible user token if possible
      return { status: false, data: err }
    })
  },
  async GET_RESET_PASSWORD_INSTRUCTIONS (_, data) {
    return await this.$axios.$post(backend.GET_RESET_PASSWORD_LINK, {
      user: {
        email: data.email
      }
    }).then((resp) => {
      return { status: true, data: resp }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async RESET_PASSWORD (_, data) {
    // commit('AUTH_REQUEST')
    return await this.$axios.$post(backend.RESET_PASSWORD(data.token), {
      user: {
        password: data.password,
        password_confirmation: data.passwordBis
      }
    }).then((resp) => {
      return { status: true, data: resp }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async AUTH_CONFIRM_USER (_, { token }) {
    // commit('AUTH_REQUEST')
    return await this.$axios.$get(backend.CONFIRM_USER(token)).then((resp) => {
      return { status: true, data: resp }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async AUTH_LOGOUT ({ commit }) {
    console.log('LOGOUT RESQUEST')
    commit('AUTH_LOGOUT')
    return await this.$axios.delete(backend.LOGOUT).then((resp) => {
      if (this.$phxSocket.channel) {
        this.$phxSocket.channel.leave()
        this.$phxSocket.channel = null
      }
      if (this.$phxSocket.socket) {
        this.$phxSocket.socket.disconnect()
        this.$phxSocket.socket = null
      }
      Storage.remove('user-token') // clear your user's token from localstorage
      Storage.remove('events')
      Storage.remove('user')
      this.$axios.setToken(false)
      return { status: true, data: resp }
    }).catch((err) => {
      console.log('error', err)
      return { status: false, data: err }
    })
  },
  async AUTH_REGISTER ({ dispatch }, user) {
    return await this.$axios.post(backend.REGISTER, {
      user: {
        email: user.email,
        lastname: user.lastname,
        firstname: user.firstname,
        job_title: user.jobTitle,
        password: user.password,
        terms_of_service: user.termAndConditions,
        privacy_policy: user.termAndConditions
      }
    }).then((resp) => {
      return { status: true, data: resp.data }
    }).catch((err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Register Error', err)
      }
      dispatch('SHOW_ERROR', { message: err.response.data.message }, { root: true })
      return { status: false, data: err }
    })
    // })
  },
  async FETCH_USER ({ commit }) {
    return await this.$axios.get('user').then((resp) => {
      const user = resp.data.user
      commit('SET_USER', user)
      Storage.setObject('user', user) // Store the user on localstorage
      return { status: true, data: resp }
    }).catch((err) => {
      console.log(err.reponse)
      return { status: false, data: err }
    })
  },
  async FETCH_APPS ({ commit }) {
    return await this.$axios.get(backend.LINKED_APPS).then((resp) => {
      const apps = resp.data.apps
      commit('SET_APPS', apps)
      return { status: true, data: resp }
    }).catch((err) => {
      console.log(err.reponse)
      return { status: true, data: err }
    })
  },
  async DROP_APP ({ dispatch }, type) {
    return await this.$axios.delete(backend.DROP_LINKED_APPS(type)).then((resp) => {
      dispatch('FETCH_USER')
      return { status: true, data: resp }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async UPDATE_AVATAR (_context, base64Image) {
    const user = { avatar: base64Image }
    // delete user.email
    return await this.$axios.put(backend.UPDATE_USER_SETTINGS, {
      action: 'update_avatar',
      user
    }).then((resp) => {
      return { status: true, data: resp }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async UPDATE_USER (_context, user) {
    return await this.$axios.put(backend.UPDATE_USER_SETTINGS, {
      action: 'update_informations',
      user
    }).then((resp) => {
      return { status: true, data: resp }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async UPDATE_PASSWORD (_context, data) {
    return await this.$axios.put(backend.UPDATE_USER_SETTINGS, {
      action: 'update_password',
      ...data
    }).then((resp) => {
      return { status: true, data: resp.data }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async UPDATE_EMAIL (_context, data) {
    return await this.$axios.put(backend.UPDATE_USER_SETTINGS, {
      action: 'update_email',
      ...data
    }).then((resp) => {
      return { status: true, data: resp.data }
    }).catch((err) => {
      return { status: false, data: err }
    })
  },
  async CONFIRM_EMAIL_UPDATE (_, { token }) {
    // commit('AUTH_REQUEST')
    return await this.$axios.$get(backend.CONFIRM_EMAIL_UPDATE(token)).then((resp) => {
      return { status: true, data: resp }
    }).catch((err) => {
      return { status: false, data: err }
    })
  }
}

export default actions
