import Storage from '~/misc/storage'
import backend from '~/misc/api-routes'
import { setBearerToken } from '~/misc/tools'

// const BAD_REQUEST = 'BAD_REQUEST'

const Console = console

function handleErrorMessages (err, dispatch) {
  const errors = err.response.data.errors
  // Request made and server responded
  const msg = { message: errors.message }
  if (errors.action) {
    msg.action = errors.action
  }
  if (errors && errors.type && errors.type === 'warning') {
    dispatch('SHOW_WARNING', msg, { root: true })
  } else {
    dispatch('SHOW_ERROR', msg, { root: true })
  }
}

const actions = {

  async FETCH_EVENTS ({ commit, dispatch, rootState }) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.get(backend.FETCH_EVENTS)
      .then((resp) => {
        const events = resp.data.events
        commit('SET_EVENTS', events)
        Storage.remove('events')
        Storage.setObject('events', events)
        return { status: true, data: resp }
      }).catch((err) => {
        if (err.response) {
          handleErrorMessages(err, dispatch)
          return { status: false, data: err.response }
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', err.message)
        }
        return { status: false, data: err }
      })
  },
  async LIST_CALENDARS ({ commit, dispatch, rootState }) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.get(backend.LIST_CALENDARS)
      .then((resp) => {
        return { status: true, data: resp.data }
      }).catch((err) => {
        if (err.response) {
          handleErrorMessages(err, dispatch)
          return { status: false, data: err.response }
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', err.message)
        }
        return { status: false, data: err }
      })
  },
  async WATCH_CALENDARS ({ commit, dispatch, rootState }, calendars) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.post(backend.WATCH_CALENDARS, { calendars })
      .then((resp) => {
        // resp.data = calendars
        return { status: true, data: resp.data }
      }).catch((err) => {
        if (err.response) {
          handleErrorMessages(err, dispatch)
          return { status: false, data: err.response }
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', err.message)
        }
        return { status: false, data: err }
      })
  },
  async FETCH_EVENTS_FOR_PERIOD ({ commit, dispatch, rootState }, date) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.post('user/calendar/events_for_period', { ...date })
      .then((resp) => {
        const events = resp.data.events
        commit('SET_EVENTS', events)
        Storage.remove('events')
        Storage.setObject('events', events)
        return { status: true, data: resp }
      }).catch((err) => {
        if (err.response) {
          // Request made and server responded
          handleErrorMessages(err, dispatch)
          return { status: false, data: err.response }
        } else if (err.request) {
          // The request was made but no response was received
          // console.log(err.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', err.message)
        }
        return { status: false, data: err }
      })
  },
  async PROCESS_EVENT ({ commit, rootState, state }, payload) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.put('user/calendar/event', { event: payload })
      .then((resp) => {
        const event = resp.data
        commit('UPDATE_EVENT', event)
        Storage.remove('events')
        Storage.setObject('events', state.events)
        return { status: true, data: resp }
      }).catch((err) => {
        // const status = err.response.status
        // if (status === BAD_REQUEST) {
        //   const data = err.response.data
        //   Console.log(data.error)
        // }
        // Console.log('error update event store', err.response)
        return { status: false, data: err.response }
      })
  },
  async UPDATE_EVENTS ({ commit, rootState }, eventsList) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.put('user/calendar/events', { events: eventsList })
      .then((resp) => {
        const events = resp.data.events
        commit('SET_EVENTS', events)
        Storage.remove('events')
        Storage.setObject('events', events)
        return { status: true, data: resp }
      }).catch((err) => {
        Console.log(err.reponse)
        return { status: false, data: err }
      })
  },
  async DELETE_EVENT ({ commit, rootState }, event) {
    setBearerToken(this.$axios, rootState.user)
    return await this.$axios.delete(`user/calendar/event/${event.calendar_id}/${event.id}`)
      .then((resp) => {
        console.log(resp)
        commit('DELETE_EVENT', event)
        return { status: true, data: resp }
      })
      .catch((err) => {
        Console.log(err.reponse)
        return { status: false, data: err }
      })
  }
}

export default actions
