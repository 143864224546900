import Bowser from 'bowser'
// import DOMPurify from 'dompurify'
// import { marked } from 'marked'
let md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true
})
  .use(require('markdown-it-footnote'))
  .use(require('markdown-it-emoji'))
  .use(require('markdown-it-mark'))
  .use(require('markdown-it-sup'))
  .use(require('markdown-it-abbr'))

const spoiler = {
  validate: (params) => {
    return params.trim().match(/^spoiler\s+(.*)$/)
  },

  render: (tokens, idx) => {
    const m = tokens[idx].info.trim().match(/^spoiler\s+(.*)$/)

    if (tokens[idx].nesting === 1) {
      // opening tag
      return '<details><summary>' + md.utils.escapeHtml(m[1]) + '</summary>\n'
    } else {
      // closing tag
      return '</details>\n'
    }
  }
}

md = md.use(require('markdown-it-container'), 'spoiler', spoiler)

export const regex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,160}))$/,
  password: /^.{12,80}$/,
  name: /^.{1,80}$/,
  jobTitle: /^.{1,80}$/,
  notEmptyString: /^.{1,}$/
}

export function setBearerToken (axios, state) {
  if (!axios.defaults.headers.common.Authorization) {
    axios.setToken(state.token, 'Bearer')
  }
}

export function email (email) {
  return regex.email.test(email)
}

export function password (password) {
  return regex.password.test(password)
}

export function notEmptyString (str) {
  return regex.notEmptyString.test(str)
}

export function name (name) {
  return regex.name.test(name)
}

export function appsToBlock (name) {
  return regex.name.test(name)
}

export function jobTitle (jobTitle) {
  return regex.jobTitle.test(jobTitle)
}

export function formatBytes (bytes, decimals = 2) {
  if (bytes === 0) { return '0 Octets' }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const blobToBase64 = (blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const base64 = reader.result
      resolve(base64)
    }
  })
}

export const detectBrowser = () => {
  const bowser = Bowser.getParser(window.navigator.userAgent)
  // Opera 8.0+
  // eslint-disable-next-line no-undef
  // const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.includes(' OPR/')
  const isOpera = bowser.isBrowser(Bowser.BROWSER_MAP.opera)

  // Firefox 1.0+
  // const isFirefox = typeof InstallTrigger !== 'undefined'
  const isFirefox = bowser.isBrowser(Bowser.BROWSER_MAP.firefox)

  // Safari 3.0+ "[object HTMLElementConstructor]"
  // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
  //   return p.toString() === '[object SafariRemoteNotification]'
  // // eslint-disable-next-line dot-notation
  // })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))
  const isSafari = bowser.isBrowser(Bowser.BROWSER_MAP.safari)

  // Internet Explorer 6-11
  // eslint-disable-next-line spaced-comment
  const isIE = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  // const isEdge = !isIE && !!window.StyleMedia
  const isEdge = bowser.isBrowser(Bowser.BROWSER_MAP.edge)

  // Chrome 1 - 79
  // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  const isChrome = bowser.isBrowser(Bowser.BROWSER_MAP.chrome)

  const isChromium = bowser.isBrowser(Bowser.BROWSER_MAP.chromium)

  // Edge (based on chromium) detection
  const isEdgeChromium = isChrome && (navigator.userAgent.includes('Edg'))

  // Blink engine detection
  const isBlink = (isChrome || isOpera) && !!window.CSS

  return {
    isFirefox,
    isChrome,
    isChromium,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isEdgeChromium,
    isBlink
  }
}

export function toColor (value) {
  const colors = {
    blue: 'blue',
    indigo: 'indigo',
    'deep-purple': 'deep-purple darken-1',
    teal: '#7ae7bf',
    red: 'red',
    cyan: 'cyan',
    yellow: 'yellow',
    green: 'green',
    orange: 'orange',
    grey: 'grey darken-1'
  }
  return colors[value] || value
}

export function mkToHTML (markdownContent) {
  // return DOMPurify.sanitize(marked.parse(markdownContent))
  return md.render(markdownContent)
}
// export function toFR (value) {
//   const colors = {
//     blue: 'bleu',
//     indigo: 'indigo',
//     'deep-purple': 'violet',
//     'deep-purple darken-1': 'violet foncé',
//     'teal accent-2': 'vert clair',
//     red: 'rouge',
//     cyan: 'cyan',
//     yellow: 'jaune',
//     green: 'vert',
//     orange: 'orange',
//     'grey darken-1': 'gris foncé'
//   }
//   return colors[value]
// }

export default {
  email,
  password,
  notEmptyString,
  name,
  jobTitle,
  setBearerToken,
  appsToBlock,
  formatBytes,
  blobToBase64,
  detectBrowser,
  mkToHTML
}
