import Storage from '~/misc/storage'

const mutations = {
  SET_OAUTH_URLS: (state, urls) => {
    state.oauth_urls = urls
    Storage.remove('oauth_urls')
    Storage.setObject('oauth_urls', urls) // Store the token on localstorage
  }
}

export default mutations
